import React from 'react';
import ErrorScreen from '../components/ErrorScreen';

const PageNotFound = () => {
  return (
      <ErrorScreen />
  )
};

export default PageNotFound;
