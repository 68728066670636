import React from 'react';

const Footer = () => {
    return (
        <div id='footer'>
            <ul className="copyright">
                <li>&copy; Alexandra To. All rights reserved.</li><li>Template Design: <a href="http://html5up.net">HTML5 UP</a></li><li>Web Development by Durand Enterprises</li>
            </ul>
        </div>
    )
}

export default Footer
